@import 'styles/_variables';

.description-legal-pages {
    h3 {
        line-height: 2.6rem;
    }

    p {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }

    img {
        margin-top: $base-spacing;
    }
}
