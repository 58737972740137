@import 'styles/_variables.scss';

.pdf-sub-result-item__container {
    margin-bottom: 2.4rem;

    .pdf-sub-result-item__title {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;

        &--bold {
            font-weight: 700;
        }
    }

    .pdf-sub-result-bar {
        width: 100%;
        height: 0.6rem;
        background-color: $greyCloudy;
        border-radius: 8px;

        &__content {
            height: 100%;
            border-radius: 8px;

            &--PRESTIGE {
                background-color: $prestige;
            }

            &--SOCIETAL {
                background-color: $societal;
            }

            &--CONDITION {
                background-color: $condition;
            }

            &--MISSION {
                background-color: $mission;
            }

            &--SOCIAL {
                background-color: $social;
            }
        }
    }

    .pdf-sub-result-item__description {
        color: $greyGraphiteLight;
    }
}
