@import 'styles/_variables';

.panel {
    background-color: $black;
    color: white;
    display: flex;
    width: $panel-width;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-mobile) {
        min-height: 100%;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        min-width: auto;
        background-color: transparent;
    }

    &.panel--open {
        background-color: $black;
        min-height: 100%;
    }
}

.panel__header {
    background-color: $black;
    display: flex;
    justify-content: space-between;
    padding: 24px;
}

.panel__initials {
    margin: 30px 0 38px;
}

.panel__title {
    font-size: 2.2rem;
}

.panel__inner {
    margin: 50px $base-spacing $base-spacing;
}
