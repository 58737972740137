@import 'styles/_variables.scss';

.pdf__container {
    background-color: $white;
    height: 841px;
    width: 595px;

    .pdf__content {
        height: 810px;
        width: 100%;

        h2 {
            font-size: 2.6rem;
            font-weight: 800;
            line-height: 3.4rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2rem;
            margin-top: 0;

            strong {
                font-weight: 700;
            }
        }
    }

    &.backpage {
        // Height adjustement for the backpage because puppeteer render an additional page else.
        height: 840px;

        .pdf__content {
            height: 809px;
        }
    }
}
