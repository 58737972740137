@import 'styles/_variables.scss';

.pdf-value-explanation-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pdf-value-explanation-page__heading {
        display: flex;
        justify-content: space-between;
        background-color: $whiteSmoke;
        padding: 3.6rem 0 2.4rem 4rem;

        h3 {
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2.4rem;
            margin-top: 1.6rem;
        }

        p {
            margin-top: 0.4rem;
            max-width: 50rem;
        }
    }

    .pdf-value-explanation-page__content {
        margin: 2.6rem 4rem;
    }
}
