@import 'styles/_variables';

.diagram-part__fill {
    border-radius: 50%;
}

.diagram-part {
    position: absolute;
    top: 0;
    left: 0;

    &--PRESTIGE {
        transform: rotate(0deg);

        .diagram-part__fill {
            background-color: $prestige;
        }
    }

    &--SOCIETAL {
        transform: rotate(72deg);

        .diagram-part__fill {
            background-color: $societal;
        }
    }

    &--CONDITION {
        transform: rotate(144deg);

        .diagram-part__fill {
            background-color: $condition;
        }
    }

    &--MISSION {
        transform: rotate(216deg);

        .diagram-part__fill {
            background-color: $mission;
        }
    }

    &--SOCIAL {
        transform: rotate(288deg);

        .diagram-part__fill {
            background-color: $social;
        }
    }
}

.diagram-part__full-circle {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $greyCloudy;
    background-color: $white;
    border-radius: 50%;
    overflow: hidden;
}

.diagram-part__shape {
    clip-path: polygon(50% 50%, -30% 0, 63% 0);
}
