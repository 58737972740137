@import 'styles/_variables.scss';

.pdf-front-page__container {
    background-color: $white;
    height: 841px;
    width: 595px;
}

.pdf-front-page__content {
    display: flex;
    flex-direction: column;
    height: 790px;
    width: 100%;
}

.pdf-front-page__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .jobteaser-logo {
        margin: 3.6rem;
    }
}

.pdf-front-page__text-container {
    margin-left: 3.6rem;

    h1 {
        font-size: 4.2rem;
        font-weight: $mediumBold;
        line-height: 5rem;
    }

    .date {
        color: $greyGraphiteLight;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    .subtitle {
        color: $black;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 1.7rem;
    }
}

.pdf-front-page__freya-logo-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.pdf-front-page__footer {
    background-color: $whiteSmoke;
    color: $black;
    font-size: 0.8rem;
    font-weight: 300;
    height: 5.2rem;
    line-height: 1.2rem;
    padding: 0.8rem 0.4rem;
    text-align: center;
    width: 100%;
}
