@import 'styles/_variables';

.field {
    margin-bottom: 16px;
    text-align: left;

    input {
        width: 100%;
        padding: 12px 16px;
        font-size: 16px;
        border: solid 1px $grey;
        border-radius: $border-radius;

        &::placeholder {
            color: $grey;
        }
    }

    label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
    }

    span {
        display: block;
        margin: 10px 0;
        padding-bottom: 5px;
        text-align: left;
        font-size: 11pt;
        color: $pinkRed;
        border-bottom: 1px solid lighten($pinkRed, 30%);
    }

    &--error {
        input {
            border: solid 1px $pinkRed;
            background-color: #fdf4f7;
            color: $pinkRed;

            &::placeholder {
                color: lighten($pinkRed, 15%);
            }
        }
    }

    &--warning {
        input,
        input::placeholder,
        span,
        a {
            color: $emerald;
            border-color: $emerald;
        }

        input {
            border: solid 1px;
            background-color: lighten($emerald, 65%);

            &::placeholder {
                color: $emerald;
            }
        }

        span {
            border-bottom: 1px solid;
            line-height: 1.27;
        }
    }
}

.field__label--hidden {
    position: absolute;
    visibility: hidden;
}
