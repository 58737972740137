@import 'styles/_variables.scss';

.pdf-content-presentation__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pdf-content-presentation__heading {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 5.3rem 0 2.6rem 4rem;
        }

        img {
            height: 86px;
            width: 169px;
        }
    }

    p {
        margin: 0 4rem;
    }

    .pdf-content-presentation__title-container {
        display: flex;
        margin: 4.3rem 4rem 0;

        h3 {
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2.8rem;
            margin-left: 0.8rem;
        }
    }

    ul {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem;
        list-style: disc inside;
        margin: 0 4rem;

        li {
            margin-top: 0.8rem;
        }

        strong {
            font-weight: 700;
        }
    }
}
