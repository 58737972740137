@import 'styles/_variables';

.slider-form {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20px;
    margin-top: 45px;
}

.slider-form__thumb {
    top: 5px;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: $pink;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    outline: none;
}

.slider-form__value {
    position: relative;
    top: -40px;
    left: 8px;
    width: 60px;
    line-height: 25px;
    font-size: 1.2rem;
    background-color: $white;
    border: 1px solid $greyDark;
    border-radius: 4px;

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        background-color: white;
        bottom: -6px;
        left: -1px;
        width: 6px;
        height: 10px;
        border-left: 1px solid $greyDark;
        z-index: 99;
    }

    &::after {
        bottom: -4px;
        left: -6px;
        width: 10px;
        height: 8px;
        border-right: 1px solid $greyDark;
        z-index: 100;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.slider-form__track {
    top: 12px;
    height: 8px;
    background-color: $greyCloudy;
    border-radius: 20px;

    &-0 {
        background-color: $pink;
    }
}
