@import 'styles/_variables';

.restitution-actions {
    .button {
        padding: $small-spacing 20px;
    }
}

.share-button {
    background-color: black;
    color: white;
    border: 1px solid white;
}
