@import 'styles/_variables';

.export-form {
    .label {
        margin-bottom: $x-small-spacing;
        font-size: 1.4rem;
    }

    .dates {
        @media screen and (min-width: $breakpoint-mobile) {
            width: 100%;
            display: inline-flex;

            > :first-child {
                margin-right: $small-spacing;
            }

            .field {
                width: 50%;
                margin-bottom: 0;
            }
        }
    }

    .language {
        margin-top: $medium-spacing;

        .select-field {
            margin-bottom: $medium-spacing;
        }
    }

    .submit-button {
        width: 100%;
        display: flex;
        justify-content: center;

        @media screen and (max-width: $breakpoint-mobile) {
            & > button {
                width: 100%;
            }
        }
    }
}
