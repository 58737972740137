@import 'styles/_variables';

.home-content {
    padding: 96px 53px 0;

    @media screen and (min-width: $breakpoint-desktop) {
        padding: 0;
    }

    @media screen and (max-width: $breakpoint-small-mobile) {
        padding: 96px 20px 0;
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: $max-width-home;
        margin: auto;
    }

    &__logo {
        display: block;
        margin-right: 40px;

        img {
            max-height: 70px;
        }
    }

    &__title {
        width: 100%;
        margin-bottom: 10px;
        line-height: 1.31;
        font-size: 4.2rem;
        font-weight: $extraBold;

        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 3.2rem;
        }
    }

    &__text {
        margin-bottom: 20px;
        line-height: $base-spacing;

        &:last-of-type {
            margin-bottom: 35px;
        }

        &--top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            &--top {
                max-width: 460px;
                margin-left: 30px;
                z-index: 11;
                align-items: flex-start;
            }
        }
    }

    &__top {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            text-align: left;
        }

        .home-block-cards {
            width: 100%;
            margin-top: 48px;
            position: relative;

            > :first-child {
                margin-bottom: $base-spacing;
            }

            &__wrapper {
                padding: 0 12px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                > :first-child {
                    margin-bottom: $base-spacing;
                    margin-right: $base-spacing;
                }

                @media screen and (min-width: $breakpoint-mobile) {
                    flex-direction: row;

                    > :first-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media screen and (min-width: $breakpoint-desktop) {
                display: none;
            }
        }
    }

    &__end {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        margin-top: -10px;
        padding: 60px 8.5%;
        background-color: $pink;

        @media screen and (min-width: $breakpoint-mobile) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        &-title {
            margin: auto auto 36px;
            max-width: 800px;
            text-align: center;
            font-size: 4.2rem;
            font-weight: $extraBold;
            line-height: 1.1;
        }
    }

    &__bottom {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -1px;
        flex-direction: column-reverse;

        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            margin-top: 96px;
        }

        &-text {
            max-width: none;
            margin-left: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;

            @media screen and (min-width: $breakpoint-desktop) {
                margin-left: 8.5%;
                align-items: flex-start;
                text-align: left;
            }
        }

        &-image {
            max-width: 100%;
        }

        &-logos {
            display: flex;
            margin-top: $base-spacing;
        }
    }
}

.home-cards {
    display: none;

    @media screen and (min-width: $breakpoint-desktop) {
        display: flex;
        position: relative;
        flex-direction: row;
        order: 0;
        z-index: 100;
        margin-top: 30px;

        &--left {
            margin-right: 36px;
        }

        &--right {
            margin-right: 36px;
            margin-top: -80px;
        }

        .home-card {
            margin: 0 0 32px;
        }
    }
}

.home-expectation {
    display: flex;
    width: 100%;
    margin: 96px auto 0;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-desktop) {
        flex-direction: row;
        align-items: center;
    }

    &__text {
        margin-bottom: 36px;

        @media screen and (min-width: $breakpoint-desktop) {
            margin: 0;
            max-width: 500px;
        }
    }
}

.home-your-way {
    display: flex;
    width: 100%;
    margin: 96px auto;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey;
    padding: 32px $base-spacing 0;

    @media screen and (min-width: $breakpoint-desktop) {
        max-width: 760px;
    }

    @media screen and (max-width: $breakpoint-small-mobile) {
        flex-direction: column;
    }

    &__text {
        margin-bottom: 32px;

        @media screen and (max-width: $breakpoint-small-mobile) {
            margin-top: 30px;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            max-width: 500px;
        }

        p {
            margin-top: 0;
        }
    }

    &__title {
        font-size: 22px;
    }

    &__other-products {
        list-style: unset;
        margin-left: $base-spacing;

        a {
            color: $black;
        }
    }

    &__button {
        margin-top: 1.6rem;
        padding: 7px 16px;
        min-height: unset;
        min-width: 105px;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }
    }
}
