@import 'styles/_variables';

strong,
.strong {
    font-weight: $bold;
}

.center {
    text-align: center;
}

.base-margin {
    margin-top: $base-spacing;
}

.block {
    display: block;
}
