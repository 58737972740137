@import 'styles/_variables';

.error-page-main {
    .language-switcher {
        width: 100%;
        max-width: 38rem;

        label {
            font-size: 1.4rem;
        }
    }
}
