@import 'styles/_variables';

.not-found-button__wrapper {
    margin: 3.6rem 0;

    .not-found-button {
        border-radius: $border-radius;
        border: 0;
        font-size: 1.6rem;
        font-weight: 400;
        padding: 1.2rem 2.6rem;
        text-decoration: none;
        background-color: $black;
        color: $white;

        @media screen and (max-width: $breakpoint-mobile) {
            align-self: stretch;
            margin: auto;
            padding: 1.2rem 1.7rem;
            text-align: center;
        }

        &:hover {
            background-color: lighten($black, 20%);
        }
    }
}
