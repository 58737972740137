@import 'styles/_variables.scss';

.pdf__footer {
    background-color: $whiteSmoke;
    color: $black;
    display: flex;
    font-size: 1rem;
    font-weight: 300;
    height: 3.2rem;
    justify-content: space-between;
    line-height: 1.6rem;
    padding: 0.8rem 3.6rem;
    width: 100%;
}
