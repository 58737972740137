@import 'styles/_variables';

.main {
    display: flex;
    flex-direction: column;
    padding: $large-spacing 60px 60px;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: $medium-spacing 0 0;
    }

    &--centered {
        align-items: center;
        margin: auto;
        max-width: 714px;
        padding: 50px 50px;
        text-align: center;

        @media screen and (max-width: $breakpoint-mobile) {
            align-items: inherit;
            margin: 0;
            max-width: inherit;
            padding: 50px;
            text-align: left;
        }
    }
}

.main__buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: $base-spacing;

    & > :nth-child(2) {
        margin-top: $small-spacing;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        flex-direction: row;
        justify-content: center;

        & > :nth-child(2) {
            margin-left: $small-spacing;
            margin-top: 0;
        }
    }
}
