@import 'styles/_variables.scss';

.roti__choicefield-wrapper {
    display: flex;
    justify-content: space-between;
}

.roti__choicefield-label-wrapper {
    margin-top: 0.8rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
}

.rotiQuestion__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0 1.6rem;
}
