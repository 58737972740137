@import 'styles/_variables';

.panelDescription {
    &__step {
        padding: $small-spacing;
        border-left: 2px solid $pink;

        &--hidden {
            color: $greyGraphiteLight;
            border-color: $greyGraphiteLight;
        }
    }
}
