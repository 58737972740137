@import 'styles/_variables.scss';

.pdf-value-explanation__container {
    margin-bottom: 2rem;

    .pdf-value-explanation__heading {
        display: flex;
        margin-bottom: 1.2rem;
        border: 2px solid;
        border-radius: 16px;

        &--PRESTIGE {
            border-color: $prestige;
        }

        &--SOCIETAL {
            border-color: $societal;
        }

        &--CONDITION {
            border-color: $condition;
        }

        &--MISSION {
            border-color: $mission;
        }

        &--SOCIAL {
            border-color: $social;
        }

        &-name {
            font-size: 2rem;
            font-weight: 700;
            line-height: 2.5rem;
            padding: 1.2rem 0 1.2rem 1.6rem;
        }

        &-text {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.5rem;
            padding: 1.7rem 0.8rem;
        }
    }

    .pdf-value-explanation__content {
        .title {
            font-weight: 700;
            margin-top: 0.8rem;
        }
    }
}
