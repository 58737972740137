@import 'styles/_variables';

.button {
    padding: 12px 32px;
    min-height: 50px;
    height: auto;
    font-size: 16px;
    font-weight: $semiBold;
    color: inherit;
    text-decoration: none;
    border-radius: $border-radius;
    background-color: $white;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $greyCloudy;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &--small-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    &--black {
        color: $white;
        background-color: $black;

        &:hover {
            background-color: lighten($black, 20%);
        }

        &:disabled {
            color: $grey;
            cursor: not-allowed;
            background-color: lighten($black, 89%);
        }
    }

    &--blue {
        background-color: $blue;

        &:hover {
            background-color: lighten($blue, 5%);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $blueDark;
        }
    }

    &--grey {
        background-color: $greyLighter;

        &:hover {
            background-color: darken($greyLighter, 5%);
        }
    }

    &--white {
        border: 1px solid $black;
        color: $black;
    }

    &--block {
        width: 100%;
    }

    &--block-mobile {
        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }
    }

    &--transparent {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    &--center {
        margin: 0 auto;
    }

    img {
        margin-right: 10px;
    }
}
