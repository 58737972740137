@import 'styles/_variables';

.restitution__title {
    font-size: 1.8rem;
    font-weight: $bold;
    line-height: 2.6rem;
    margin-bottom: 1.6rem;

    &--value {
        font-size: 3rem;
        font-weight: $mediumBold;
        line-height: 4rem;
    }

    &--lowercase {
        text-transform: lowercase;
    }
}

.restitution__secondary-title {
    font-size: 3.2rem;
    font-weight: $extraBold;
}

.restitution__bottom {
    width: 100%;
    margin-top: $medium-spacing;
    padding: $medium-spacing;
    background-color: $whiteSmoke;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: $medium-spacing 20px;
        margin-bottom: -$medium-spacing;
        width: calc(100% + 40px);
        margin-left: -20px;
    }
}

.restitution__diagram {
    display: flex;
    justify-content: center;
}
