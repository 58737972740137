@import 'styles/_variables';

.card-game__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: $medium-spacing 0;
    width: 100%;
}

.card-game__shadow {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    z-index: 1;

    &:focus {
        outline: none;
    }

    &:not(.card-game__shadow--disabled):hover,
    &--selected {
        z-index: 2;
    }

    &--left {
        transform: rotate(-18deg) translateX(-30px) translateY(40px);

        @media screen and (max-width: $breakpoint-mobile) {
            transform: scale(0.95) rotate(-17deg) translateX(20px) translateY(-20px);
        }
    }

    &--right {
        transform: rotate(15deg) translateX(30px);

        @media screen and (max-width: $breakpoint-mobile) {
            transform: scale(0.95) rotate(15deg) translateX(-20px) translateY(5px);
        }
    }
}

.card-game__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 230px;
    min-height: 300px;
    padding: 24px;
    font-size: 2rem;
    background-color: $white;
    border: 5px solid $white;
    border-radius: $border-radius;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.6rem;
        min-height: 270px;
        padding: 30px;
        width: 200px;
    }

    .card-game__shadow--left & {
        transform: scale(1.05) rotate(10deg) translateX(15px) translateY(-20px);

        @media screen and (max-width: $breakpoint-mobile) {
            transform: scale(1.05) rotate(8deg) translateX(15px) translateY(-15px);
        }
    }

    .card-game__shadow--right & {
        transform: scale(1.05) rotate(-5deg) translateX(-15px) translateY(-25px);

        @media screen and (max-width: $breakpoint-mobile) {
            transform: scale(1.05) rotate(-5deg) translateX(10px) translateY(0);
        }
    }

    .card-game__shadow--selected & {
        border: 5px solid $black;
    }

    .card-game__shadow--disabled & {
        cursor: default;
    }

    @media (hover: hover) {
        .card-game__shadow:not(.card-game__shadow--disabled):hover &,
        .card-game__shadow:not(.card-game__shadow--disabled):focus & {
            border: 5px solid $black;
        }
    }
}

.card-game__num {
    position: absolute;
    left: 50%;
    bottom: 10px;
    font-size: 1.2rem;
    font-weight: 600;
}

.card-game__text {
    margin-top: -20px;

    strong {
        font-weight: 900;
    }
}
