@import 'styles/_variables';

.admin {
    h1,
    h2 {
        margin-bottom: $medium-spacing;
        font-weight: $mediumBold;
    }

    ul {
        padding-left: $base-spacing;
    }

    .main-card {
        @media screen and (min-width: $breakpoint-mobile) {
            max-width: 864px;
            border-radius: 16px;
            min-height: unset;
        }
    }

    &__back-button {
        padding: 0;
        color: $white;
    }

    &__title {
        font-size: 3rem;
    }

    &__list {
        list-style: disc;
        font-size: 1.6rem;
        margin-bottom: $medium-spacing;
    }

    &__spinner {
        margin-top: 50px;
        text-align: center;
    }
}
