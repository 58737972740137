.static-page {
    padding-bottom: 20px;

    .hero {
        padding: 30px;
        background-color: $greyLighter;
        border-radius: $border-radius;
    }

    h1 {
        margin-bottom: $base-spacing;
        font-size: 4.2rem;
        font-weight: bold;
    }

    h2 {
        margin-top: $medium-spacing;
        margin-bottom: $small-spacing;
        font-size: 2.2rem;
        font-weight: bold;
    }

    section {
        margin: $large-spacing 0;
        padding: $medium-spacing;
        background-color: $whiteSmoke;
    }

    p {
        margin-bottom: 10px;
    }

    a {
        color: $black;
        text-decoration: underline;
    }

    li {
        margin: 10px 0 10px 20px;
        list-style: initial;
    }
}
