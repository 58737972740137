@import 'styles/_variables.scss';

.pdf-intro-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    h2 {
        margin: 5.3rem 4rem 2.6rem;
    }

    p {
        margin: 0 4rem 1.6rem;
    }

    .pdf-intro-page__intro-shape-container {
        bottom: 0;
        position: absolute;
        right: 0;
    }
}
