@import 'styles/_variables';

.descriptionAccount {
    p {
        font-size: 14px;
    }

    img {
        margin-top: $base-spacing;
    }
}
