.backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
}
