@import 'styles/_variables';

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main-back {
    padding: $base-spacing 20px;

    @media screen and (min-width: $breakpoint-mobile) {
        padding: $medium-spacing;
    }

    .main {
        padding: 0;

        @media screen and (min-width: $breakpoint-mobile) {
            margin: 0 auto;
            max-width: 664px;
        }
    }

    &__title {
        font-size: 3.2rem !important;
        margin-bottom: $base-spacing;
    }

    &__button {
        padding: 0;
        color: $greyGraphiteLight;
        margin-bottom: 4.1rem;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-bottom: 2.6rem;
        }
    }
}
