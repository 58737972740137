@import 'styles/_variables';

.home-header {
    background-color: $black;

    &__header-menu {
        background: none;
        position: relative;
        z-index: 100;

        &__wrapper {
            padding: 2.4rem;
        }

        &__pipe {
            border-left: 1px solid $white;
            height: 2.2rem;
            margin-left: 2.4rem;
            margin-right: 2.4rem;
        }

        &--right {
            align-items: center;
            display: flex;
        }

        .button {
            min-height: auto;
        }
    }

    &__title {
        color: $white;
        font-size: 4.2rem;
        font-weight: $extraBold;
        line-height: 1.1;
        margin-bottom: 0.8rem;
        max-width: 630px;

        @media screen and (min-width: $breakpoint-mobile) {
            font-size: 6rem;
            max-width: 800px;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            max-width: 450px;
        }

        span {
            display: block;
            font-size: 4.2rem;
            margin-top: 1.5rem;

            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 2.6rem;
            }
        }
    }

    &__container {
        align-items: center;
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 1fr;
        grid-template-rows: 300px auto;
        margin-bottom: -1px;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            max-height: 1000px;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            grid-column-gap: 5px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            min-height: 500px;
        }
    }

    &__intro {
        padding: 0 20px;

        @media screen and (min-width: $breakpoint-desktop) {
            margin-left: calc(100% - 600px);
        }
    }

    &__wrapper-illustration {
        height: 100%;
        margin-left: -20%;

        @media screen and (min-width: $breakpoint-mobile) {
            margin-left: -35%;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            margin: 0;
            max-height: 500px;
        }
    }

    &__illustration {
        height: 100%;
        max-height: 650px;
        max-width: unset;
        width: 100%;
    }

    &__subtitle {
        color: $white;
        font-size: 2.2rem;
        font-weight: $bold;
        line-height: 2.8rem;
        margin-bottom: 3.6rem;
    }
}
