@import 'styles/_variables';

.nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 0 35px;
    z-index: 1;

    @media screen and (max-width: $breakpoint-mobile) {
        margin: 0 0 1.5rem;
        width: 100%;
    }

    &--number {
        width: 260px;
    }
}

.nav__button {
    &:hover {
        opacity: 0.6;
    }

    &--disabled {
        opacity: 0.2;

        &:hover {
            opacity: 0.2;
        }
    }
}

.question {
    display: flex;
    align-items: flex-start;
}

.question__current {
    font-size: 4.2rem;
    font-weight: 900;
    line-height: 1;
}

.question__total {
    font-size: 1.4rem;
}

.bar {
    width: 258px;
    height: 4px;
    margin: 0 50px;
    background-color: $black;

    &__content {
        height: 100%;
        background-color: $white;
    }
}
