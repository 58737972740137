@import 'styles/_variables';

.auth-form {
    .field label {
        margin-bottom: $x-small-spacing;
    }

    .title {
        font-size: 26px;
    }

    &__redirectLink {
        @media screen and (max-width: $breakpoint-mobile) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__name-fields {
        @media screen and (min-width: $breakpoint-mobile) {
            display: flex;

            & > div:first-child {
                margin-right: $small-spacing;
            }
        }
    }
}
