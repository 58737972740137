@import 'styles/_variables.scss';

.pdf-hierarchy-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 4rem;

    h2 {
        margin-top: 5.3rem;
        margin-bottom: 2.4rem;
    }

    p {
        margin-bottom: 2.4rem;
    }

    .pdf-hierarchy-page__description {
        margin-top: 2.4rem;
    }
}
