@import 'styles/_variables.scss';

.pdf-value-explanation-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pdf-value-explanation-page__content {
        margin: 2.6rem 4rem;
    }
}
