@import 'styles/_variables.scss';

.pdf-results-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pdf-results-page__heading {
        background-color: $whiteSmoke;
        padding: 3.6rem 4rem 2.4rem;
        width: 100%;

        p {
            margin-top: 0.8rem;
        }
    }

    .pdf-results-page__content {
        display: flex;
        flex-direction: column;
        margin: 2.4rem 4rem 0;

        h3 {
            color: $pink;
            font-size: 2.2rem;
            font-weight: 800;
            line-height: 2.8rem;
            margin: 0.4rem 0 0.8rem;
        }

        .pdf-results-page__diagram-container {
            align-self: center;
            margin: 2.4rem 0;
        }
    }

    .pdf-results-page__shape-container {
        align-self: flex-end;
        margin-top: auto;
    }
}
