@import 'styles/_variables';

.share-buttons {
    display: flex;
    justify-content: space-between;

    & > * {
        width: 100%;
    }

    & > :nth-child(2) {
        margin-left: $base-spacing;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;

        & > :nth-child(2) {
            margin-left: 0;
            margin-top: $base-spacing;
        }
    }
}
