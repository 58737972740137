@import 'styles/_variables';

.home-card {
    align-items: center;
    background-color: $greyLighter;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    height: 260px;
    justify-content: center;
    padding: 28px;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
        padding: 36px;
        width: 264px;
    }

    &__title {
        font-size: 1.8rem;
        font-weight: $mediumBold;
        line-height: 2.6rem;
        margin-bottom: 8px;
    }

    &__img {
        height: 64px;
        margin-bottom: 12px;
        width: auto;
    }
}
