@import 'styles/_variables.scss';

.label {
    display: block;
    margin: 0 0 10px;
    font-weight: bold;
}

.form-group {
    margin-bottom: 40px;
}

select {
    max-width: 100%;
    padding: 10px 5px;
    border: 1px solid $grey;
}
