@import 'styles/_variables';

.language-switcher {
    .field {
        margin-bottom: 0;
    }

    &--black {
        .select_container {
            background-color: $black;
            color: $white;
            border-color: $greyGraphiteLight;
        }

        .select {
            &__control {
                background-color: $black;
                color: $white;
                cursor: pointer;

                &--is-focused {
                    border-color: $white !important;
                }
            }

            &__menu {
                background-color: $black;
                color: $white;
                border: 1px solid $greyGraphiteLight;
            }

            &__menu-list {
                background-color: $black;
                color: $white;
            }

            &__option {
                cursor: pointer;
                background-color: $black !important;
                color: $white;
            }

            &__indicator {
                color: $white !important;
            }

            &__single-value {
                color: $white;
            }
        }
    }

    // For HomeHeader and Footer
    &__small {
        .field input {
            padding: 0;
            cursor: pointer;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            max-width: 46px;
            border: 0;
            padding: 0;
            background-color: $black;
            color: $white;
            -moz-appearance: none;
            -webkit-appearance: none;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            max-width: 123px;
            padding: 7px;
            background-color: $white;
            color: $black;
            border-radius: $border-radius;
        }

        .select {
            &__control {
                min-height: unset;
                border: unset;
            }

            &__value-container {
                padding: 0;
                color: $white;
            }

            &__single-value {
                margin: 0 4px 0 0;
            }

            &__indicator {
                padding: 0;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
