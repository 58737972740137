@import 'styles/_variables';
@import 'styles/_fonts';
@import 'styles/_form';
@import 'styles/_reset';
@import 'styles/_static';
@import 'styles/_texts';
@import 'styles/_utils';

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: calc(1em * 0.625);
}

body {
    position: fixed;
    margin: 0;
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-stretch: normal;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.4;
    color: $black;

    @media screen and (max-width: $breakpoint-mobile) {
        position: relative;
    }
}

html,
body {
    height: 100%;
    width: 100%;
}

img {
    max-width: 100%;
}

#app {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: $breakpoint-mobile) {
        min-height: 100%;
    }
}
