@import 'styles/_variables';
@import 'react-confirm-alert/src/react-confirm-alert.css';

.react-confirm-alert-overlay {
    overflow: auto;
    background-color: inherit;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        height: 100%;
        justify-content: inherit;
    }
}

.react-confirm-alert {
    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
    }
}

.modal {
    position: fixed;
    height: 100%;
    box-sizing: border-box;
    z-index: 900;
    overflow: auto;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        height: auto;
        left: 50%;
        max-width: 564px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    &__inner {
        padding: 20px;
        background-color: $white;

        @media screen and (min-width: $breakpoint-mobile) {
            padding: 36px;
            border-radius: $border-radius;
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: $base-spacing;
        cursor: pointer;

        @media screen and (min-width: $breakpoint-mobile) {
            height: 16px;
            width: 16px;
            top: $medium-spacing;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 26px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;

        > * {
            margin-left: 10px;
        }
    }

    &--fullscreen {
        .modal__inner {
            @media screen and (max-width: $breakpoint-mobile) {
                min-height: 100%;
                position: relative;
                padding: $base-spacing 20px;
            }
        }
    }
}
