@import 'styles/_variables';

.descriptionExercise {
    font-size: 16px;

    h3 {
        font-weight: $bold;
    }

    &__short {
        font-size: 14px;
    }
}
