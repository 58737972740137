@import 'styles/_variables.scss';

.pdf-sub-results-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 4rem;
    padding-top: 5.3rem;

    .pdf-sub-results-page__shape-container {
        margin-top: auto;
    }
}
