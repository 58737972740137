p {
    margin-top: 10px;
}

.title {
    line-height: 1.3;
    font-size: 4.2rem;
    font-weight: $extraBold;

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 3rem;
    }
}

.title--large {
    font-size: 2.6rem;
}

.title--medium {
    font-size: 2.2rem;
}

.title--small {
    font-size: 1.8rem;
}

.text--small {
    font-size: 1.4rem;
}

.text--medium {
    font-size: 1.6rem;
}

.subtitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $greyGraphite;
    margin-top: $x-small-spacing;
}

.subheading {
    line-height: 2.8;
    font-size: 2.2rem;
    font-weight: $extraBold;
    margin-bottom: $medium-spacing;
}

.link {
    color: $black;
    text-decoration: underline;
}

.error {
    color: $pinkRed;
}
