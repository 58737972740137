@import 'styles/_variables';

.container {
    display: flex;
    min-height: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
    }

    &--blue {
        background-color: $blue;
    }

    &--pink {
        background-color: $pink;
    }

    &--white {
        background-color: $white;
    }
}

.container__panel {
    min-width: $panel-width;
}
