@import 'styles/_variables.scss';

.pdf-dimension-frame__container {
    display: flex;
    margin-bottom: 1.6rem;

    &--PRESTIGE {
        border: 2px solid $prestige;
        border-radius: 16px;
    }

    &--SOCIETAL {
        border: 2px solid $societal;
        border-radius: 16px;
    }

    &--CONDITION {
        border: 2px solid $condition;
        border-radius: 16px;
    }

    &--MISSION {
        border: 2px solid $mission;
        border-radius: 16px;
    }

    &--SOCIAL {
        border: 2px solid $social;
        border-radius: 16px;
    }
}

.pdf-dimension-frame__percentage {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.8rem;
    margin: 1.6rem;
}

.pdf-dimension-frame__name {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 2.25rem 0;
    width: 23rem;
}

.pdf-dimension-frame__separator {
    border-left: 1px solid #000;
    height: 2.4rem;
    margin: 2.25rem 1.6rem;
}

.pdf-dimension-frame__text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 1.9rem 0;
    width: 13.8rem;
}
