@import 'styles/_variables.scss';

.choicefield__wrapper {
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
        display: none;
    }
}

.choicefield {
    margin: 8px 16px 8px 0;
    padding: 6px 16px;
    border: 1px solid $grey;
    border-radius: 100px;
    cursor: pointer;
    white-space: nowrap;

    &.rounded {
        display: inline-block;
        width: 48px;
        height: 48px;
        padding: 11px 0 0;
        margin: 0;
        text-align: center;
        color: $black;
        font-weight: $bold;
        border-color: transparent;
        background-color: $whiteSmoke;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 35px;
            height: 35px;
            padding-top: 5px;
        }
    }

    &:hover,
    &--active {
        border: 2px solid $pink !important;

        &.rounded {
            color: $black;
        }
    }

    input {
        position: fixed;
        height: 0;
        width: 0;
        appearance: none;
        border-color: transparent;
        background: transparent;
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        padding: 10px;
        border: solid 1px $pinkRed;
        background-color: #fdf4f7;
        color: $pinkRed;
    }
}
