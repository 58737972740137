@import 'styles/_variables';

.analysis {
    background-color: $white;
    display: grid;
    grid-template-columns: auto 280px auto;
    grid-template-rows: 100px 100px 100px;
    height: 100%;
    max-width: 600px;
    place-items: center;
    width: 100%;

    &.pdf-size {
        grid-template-columns: auto 210px auto;
        grid-template-rows: 70px 70px 70px;
    }

    &.share-size {
        grid-template-columns: auto 350px auto;
        grid-template-rows: 120px 120px 120px;
        margin: $large-spacing;
        max-width: 750px;
    }

    @media screen and (max-width: $breakpoint-desktop) {
        grid-column-gap: $small-spacing;
        grid-row-gap: $small-spacing;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: 280px auto auto;
        place-items: flex-start;
    }
}

.diagram {
    align-items: center;
    align-self: flex-end;
    display: flex;
    grid-area: 1 / 2 / 4 / 3;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: $breakpoint-desktop) {
        grid-area: 1 / 1 / 2 / 4;

        .diagram-part {
            left: unset;
        }

        .diagram-part__full-circle {
            border: none;
        }
    }
}

.diagram__center {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
    z-index: 1;
}

.analysis__text {
    border-bottom: 1px solid $greyCloudy;
    font-size: 1.8rem;
    font-weight: $extraBold;
    line-height: 1;
    padding-bottom: 5px;
    text-align: left;
    width: 100%;

    span {
        display: block;
        font-size: 3.2rem;
    }

    @media screen and (max-width: $breakpoint-desktop) {
        border-bottom: none;
        padding-bottom: 0;
        text-align: center;
        word-break: break-all;
    }

    &--PRESTIGE {
        grid-area: 1 / 1 / 2 / 2;
        margin-right: -45px;
        padding-right: 30px;
        width: calc(100% + 50px);

        span {
            color: $purple;
        }

        @media screen and (max-width: $breakpoint-desktop) {
            grid-area: 2 / 1 / 3 / 2;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    &--SOCIETAL {
        grid-area: 1 / 3 / 3 / 4;
        margin-left: -55px;
        padding-left: 50px;
        text-align: right;
        width: calc(100% + 60px);

        span {
            color: $orange;
        }

        @media screen and (max-width: $breakpoint-desktop) {
            grid-area: 2 / 2 / 3 / 3;
            margin: 0;
            padding: 0;
            text-align: center;
            width: 100%;
        }
    }

    &--CONDITION {
        grid-area: 2 / 3 / 4 / 4;
        margin-left: -55px;
        padding-left: 45px;
        text-align: right;
        width: calc(100% + 60px);

        span {
            color: $green;
        }

        @media screen and (max-width: $breakpoint-desktop) {
            grid-area: 2 / 3 / 3 / 4;
            margin: 0;
            padding: 0;
            text-align: center;
            width: 100%;
        }
    }

    &--MISSION {
        grid-area: 3 / 1 / 4 / 2;
        margin-right: -115px;
        padding-right: 110px;
        width: calc(100% + 120px);

        span {
            color: $pink;
        }

        @media screen and (max-width: $breakpoint-desktop) {
            grid-area: 3 / 2 / 4 / 4;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    &--SOCIAL {
        grid-area: 2 / 1 / 3 / 2;
        margin-right: -25px;
        padding-right: 30px;
        width: calc(100% + 30px);

        span {
            color: $blue;
        }

        @media screen and (max-width: $breakpoint-desktop) {
            grid-area: 3 / 1 / 4 / 3;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    &.pdf-size {
        font-size: 1.6rem;

        span {
            font-size: 2.4rem;
        }
    }
}
