/* COLORS */
$white: #fff;
$marigold: #fc0;
$blue: #00cddd;
$blueDark: desaturate($blue, 30%);
$emerald: #00a34d;
$pink: #ee7ff5;
$greyPink: #cc71cc;
$black: #000;
$greyStone: #aaa;
$greyDark: #c4c4c4;
$grey: #bdbdc0;
$greyCloudy: #e3e3e7;
$greyLight: #c8c8c8;
$greyLighter: #f5f5f5;
$greyGraphite: #767678;
$greyGraphiteLight: #717173;
$pinkRed: #ff005f;
$purple: #8850ff;
$orange: #ffa800;
$green: #4dc942;
$whiteSmoke: #f6f6f9;

/* BREAKPOINTS */
$breakpoint-small-mobile: 600px;
$breakpoint-mobile: 970px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1200px;
$breakpoint-mobile-height: 550px;

/* FONT WEIGHTS */
$semiBold: 600;
$bold: 700;
$mediumBold: 800;
$extraBold: 900;

$box-shadow: rgba(0, 0, 0, 0.2);

$border-radius: 4px;
$medium-border-radius: 16px;

$max-width-home: 1160px;

$x-small-spacing: 4px;
$small-spacing: 16px;
$base-spacing: 24px;
$medium-spacing: 36px;
$large-spacing: 64px;

$header-height: 8.75rem;
$panel-width: 304px;

$header-height: 8.75rem;
$panel-width: 304px;

$prestige: $purple;
$societal: $orange;
$condition: $green;
$mission: $pink;
$social: $blue;
