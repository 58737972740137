@import 'styles/_variables.scss';

.pdf-back-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .shape-top {
        align-self: flex-end;
        height: 286px;
        width: 197px;
    }

    .pdf-back-page__content {
        text-align: center;

        .pdf-back-page__title {
            font-size: 3.2rem;
            font-weight: 800;
            line-height: 4.2rem;
            margin: 3.7rem auto 1.6rem;
            max-width: 40rem;
        }

        .pdf-back-page__subtitle {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
        }
    }

    .shape-bottom {
        align-self: flex-start;
        height: 210px;
        margin-top: auto;
        width: 177px;
    }
}
