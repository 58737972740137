@import 'styles/_variables.scss';

.checkbox {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    text-align: left;
    line-height: 1;

    input {
        margin-right: 12px;
        border: 1px solid $grey;
        transform: scale(1.2);
        flex: 0 0 16px; // fix iPhone style
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        padding: 10px;
        border: solid 1px $pinkRed;
        background-color: #fdf4f7;
        color: $pinkRed;
    }
}
