@import 'styles/_variables';

.main-steps {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
}

.main-steps__header {
    align-items: center;
    display: flex;
    flex-direction: column;

    h1 {
        margin-bottom: 7.6rem;
        margin-top: 0.8rem;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-bottom: $medium-spacing;
        }
    }
}
