@import 'styles/_variables';

.footer {
    margin-top: auto;
    color: $white;
    background-color: $black;
    display: flex;
    align-items: center;

    @media screen and (max-width: $breakpoint-mobile) {
        text-align: center;
        align-items: flex-start;
        padding-left: 3.6rem;
        padding-right: 3.6rem;

        img {
            margin: 2.4rem 0 3.6rem;
        }
    }

    .footer__content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        font-size: 1.2rem;
        margin: 2.4rem 0;

        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
            justify-content: flex-start;
            margin: 3.6rem 0;
            align-items: center;
        }

        .footer__pipe {
            border-left: 1px solid $white;
            height: 1.6rem;
            margin-right: 1.6rem;

            @media screen and (max-width: $breakpoint-mobile) {
                display: none;
            }
        }
    }

    .footer__separator {
        @media screen and (max-width: $breakpoint-mobile) {
            border-left: 0;
            border-top: 1px solid $grey;
            max-width: 342px;
            width: 100%;
            margin: 3.6rem;
        }
    }

    .footer__content--left {
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
        }

        .footer__link {
            color: $white;
            font-weight: normal;
            margin-right: 1.6rem;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            @media screen and (max-width: $breakpoint-mobile) {
                margin-right: 0;
                margin-bottom: 2.4rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
