@import 'styles/_variables';

.slider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.slider__mark {
    position: absolute;
    bottom: calc(50% - 4px);
    width: 8px;
    height: 8px;
    margin: 0 15px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
}

.slider__thumb {
    top: 2px;
    width: 48px;
    height: 48px;
    text-align: center;
    background-color: $white;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    outline: none;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 38px;
        height: 38px;
    }

    &::before,
    &::after {
        content: '';
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        top: 18px;
        border-color: $black;
        border-style: solid;
        border-width: 2px 2px 0 0;
        vertical-align: top;

        @media screen and (max-width: $breakpoint-mobile) {
            top: 15px;
            height: 8px;
            width: 8px;
        }
    }

    &::before {
        transform: rotate(-135deg);
    }

    &::after {
        transform: rotate(45deg);
    }
}

.slider__value {
    position: absolute;
    top: -50px;
    left: -8px;
    width: 60px;
    line-height: 40px;
    font-size: 1.8rem;
    font-weight: bold;
    background-color: $black;
    border-radius: 8px;
    color: $white;

    @media screen and (max-width: $breakpoint-mobile) {
        left: -14px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 19px;
        border: solid;
        border-color: $black transparent;
        border-width: 13px 13px 0 13px;
        z-index: 99;
    }

    @media screen and (max-height: $breakpoint-mobile-height) {
        line-height: 25px;
        top: -30px;
    }
}

.slider__track {
    top: 12px;
    height: 24px;
    background-color: $greyCloudy;
    border-radius: 20px;

    &-0 {
        background-color: $pink;
    }

    &__inactive {
        background-color: $greyCloudy;
    }
}

.slider__legend {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;

    & > :first-child,
    & > :last-child {
        max-width: 110px;

        @media screen and (max-width: $breakpoint-mobile) {
            max-width: 100px;
        }
    }

    & > :last-child {
        text-align: right;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.4rem;
    }
}

.slider__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $base-spacing;
    padding: 0 20px;
    width: 100%; // important for small questions
}

.slider__wrapper {
    display: flex;
    justify-content: center;
}

.slider__shadow {
    width: 100%;
    max-width: 664px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: $medium-border-radius;
    transform: scale(0.95) rotate(5deg);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: rotate(5deg);
    }
}

.slider__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 664px;
    min-height: 350px;
    padding: 48px $large-spacing;
    background-color: $white;
    border-radius: $medium-border-radius;
    transform: scale(1.05) rotate(-5deg) translateY(-6px);

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        min-height: 285px;
        padding: $medium-spacing $small-spacing;
        border-radius: $medium-border-radius;
        transform: rotate(-5deg) translateY(-3px);
    }

    @media screen and (max-height: $breakpoint-mobile-height) {
        min-height: 215px;
        padding: 10px 20px;
    }
}

.slider__title {
    font-size: 2.2rem;
    text-align: center;

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.8rem;
    }

    strong {
        font-weight: 900;
    }
}
