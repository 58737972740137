@import 'styles/_variables.scss';

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;

    &--done {
        background-color: $pink;
    }

    &--todo {
        border: 3px solid $greyCloudy;
    }
}
