@import 'styles/_variables';

.home-sso-banner {
    text-align: left;

    a {
        font-weight: $bold;
        color: $greyGraphite;
    }
}
