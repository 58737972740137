@import 'styles/_variables.scss';

.pdf-summary-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 4rem;
    padding-top: 5.3rem;

    h2 {
        margin-bottom: 2.4rem;

        &.with-margin-top {
            margin-top: 6.4rem;
        }
    }

    .with-margin-bottom {
        margin-bottom: 1.6rem;
    }
}

.pdf-summary-page__shape-container {
    position: absolute;
}
