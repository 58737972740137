@import 'styles/_variables';

.main-card {
    background-color: $white;
    border-radius: 40px;
    margin: 0 auto;
    max-width: 864px;
    padding: $large-spacing;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 0;
        margin-bottom: -$base-spacing;
        max-width: unset;
        padding: $medium-spacing 20px;
        width: 100%;
    }
}

.main-card__header {
    width: 100%;
    max-width: 864px;
    margin: 0 auto;

    @media screen and (max-width: $breakpoint-mobile) {
        max-width: unset;
        padding: 0 20px;
    }
}

.main-card--small {
    max-width: 536px;
}

.main-card__title {
    text-align: center;
    margin-bottom: $medium-spacing;
    font-size: 3.2rem !important;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: $base-spacing;
    }
}
